import { get, nthArg } from 'lodash';

import { createAction, urijs, getCategoryIds } from '@wix/communities-blog-client-common';
import { normalizePosts } from '../../common/services/post-utils';
import { getQueryLocale } from '../../common/selectors/locale-selectors';

export const FETCH_RECENT_POSTS_REQUEST = 'recentPosts/FETCH_REQUEST';
export const FETCH_RECENT_POSTS_SUCCESS = 'recentPosts/FETCH_SUCCESS';
export const FETCH_RECENT_POSTS_FAILURE = 'recentPosts/FETCH_FAILURE';

export const fetchRecentPostsRequest = createAction(FETCH_RECENT_POSTS_REQUEST);
export const fetchRecentPostsSuccess = createAction(FETCH_RECENT_POSTS_SUCCESS, nthArg(0), nthArg(1));
export const fetchRecentPostsFailure = createAction(FETCH_RECENT_POSTS_FAILURE, nthArg(0), nthArg(1));

export const POST_COUNT = 3;

export const getQuery = (post, lang) =>
  urijs('/_api/posts').query({
    categoryIds: get(post, 'categoryIds', []).join(','),
    excludeIds: post._id,
    size: POST_COUNT,
    offset: 0,
    ...(lang && { lang }),
  });

export default function fetchRecentPosts(post) {
  return (dispatch, getState, { request }) => {
    const params = { postId: post._id };
    dispatch(fetchRecentPostsRequest(params));
    const lang = getQueryLocale(getState());
    const promise = request(getQuery(post, lang));

    // prettier-ignore
    return promise
      .then(posts =>
        dispatch(fetchRecentPostsSuccess(
          normalizePosts({ state: getState(), posts, blogCategoryIds: getCategoryIds(getState()) }),
          params
        )))
      .catch(() => dispatch(fetchRecentPostsFailure(undefined, params)))
      .then(() => promise);
  };
}
